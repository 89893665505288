import {axisBottom, axisLeft} from 'd3-axis'
import {controller, target} from '@github/catalyst'
import {max as d3max, extent} from 'd3-array'
import {scaleLinear, scaleTime} from 'd3-scale'
import {timeFormat, utcFormat} from 'd3-time-format'
import d3Tip from '../d3/tip'
import {fetchPoll} from '../fetch'
import {line} from 'd3-shape'
import memoize from '@github/memoize'
import {select} from 'd3-selection'

interface GraphData {
  date: Date
  value: number
  type: string | null
}
type Data = GraphData[]
type GraphLines = Array<{class: string; name: string; delta: number; data: Data}>

const cachedData = memoize(fetchData)

async function fetchData(url: string): Promise<unknown> {
  const response = await fetchPoll(url, {headers: {accept: 'application/json'}})
  return await response.json()
}

@controller
class CommunityContributionsGraphElement extends HTMLElement {
  @target graph: HTMLElement

  async connectedCallback() {
    const graph = this.graph
    const url = graph.getAttribute('data-url')
    if (!url) {
      return
    }

    // Remove possibly existing cached graph.
    for (const el of graph.querySelectorAll('svg.viz')) {
      el.remove()
    }

    graph.classList.add('is-graph-loading')
    graph.classList.remove('is-graph-load-error', 'is-graph-empty')

    try {
      const data = await cachedData(url)
      graph.classList.remove('is-graph-loading')
      if (data) {
        render(graph)
      }
    } catch (error) {
      graph.classList.remove('is-graph-loading')
      graph.classList.add('is-graph-load-error')
      throw error
    }
  }
}

function generateAndFormatData(): GraphLines {
  const lines: GraphLines = [
    {class: 'discussions-line', name: 'discussions', delta: 0, data: []},
    {class: 'issues-line', name: 'issues', delta: 100, data: []},
    {class: 'pull-requests-line', name: 'pull requests', delta: 170, data: []}
  ]

  // TODO: remove this when we have a better way to get the data
  // generate some random data for now
  for (let i = 0; i < 31; i++) {
    for (const l of lines) {
      l.data.push({
        date: new Date(Date.now() + 24 * i * 60 * 60 * 1000),
        value: Math.floor(Math.random() * 100),
        type: l.name
      })
    }
  }

  // group the data by week
  for (const l of lines) {
    const newData = []
    for (const [i, d] of l.data.entries()) {
      if (i % 7 === 0) {
        newData.push({
          date: d.date,
          value: 0,
          type: null
        })
      }
      newData[newData.length - 1].value += d.value
    }
    l.data = newData
  }

  return lines
}

function render(container: HTMLElement) {
  const width = container.clientWidth
  const height = container.clientHeight

  const margin = {top: 50, right: 20, bottom: 40, left: 50}
  const calculatedWidth = width - margin.left - margin.right
  const calculatedHeight = height - margin.top - margin.bottom

  const longFormat = utcFormat('%B %-d %Y')
  const shortFormat = utcFormat('%-m/%-d')

  const lines = generateAndFormatData()

  const tip = d3Tip<GraphData>()
    .attr('class', 'svg-tip')
    .offset([-10, 0])
    .html(function (d) {
      // eslint-disable-next-line github/unescaped-html-literal
      return `<strong>${longFormat(d.date)}</strong><br> ${d.value} created`
    })

  // base graph
  const svg = select(container)
    .append('svg')
    .attr('class', 'viz')
    .attr('width', width)
    .attr('height', height)
    .attr('alt', 'A graph of added discussions, issues, and pull requests on this repository')
    .attr('aria-describedby', 'community-contributions-description')
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`)
    .call(tip)

  let description = ''
  for (const l of lines) {
    description += `${l.name}: `

    for (const d of l.data) {
      description += `${d.value} on ${shortFormat(d.date)} `
    }
  }

  svg
    .append('div')
    .attr('text', description)
    .attr('style', 'display: none;')
    .attr('id', 'community-contributions-description')

  // legend
  for (const l of lines) {
    svg
      .append('line')
      .attr('x1', margin.right + l.delta)
      .attr('y1', -margin.top / 2)
      .attr('x2', margin.right + 16 + l.delta)
      .attr('y2', -margin.top / 2)
      .attr('class', l.class)

    svg
      .append('text')
      .attr('x', margin.right + 21 + l.delta)
      .attr('y', -margin.top / 2 + 5)
      .attr('class', 'legend-text')
      .text(l.name)
  }

  // dates should be uniform across all lines
  const [startDay, endDay] = extent(lines[0].data, c => c.date)

  // need to check all lines for maximum value
  let max = 0
  for (const l of lines) {
    max = Math.max(d3max(l.data, c => c.value) || -Infinity, max)
  }

  // x-axis
  const xTickValues = lines[0].data.map(c => c.date)
  const x = scaleTime().domain([startDay!, endDay!]).range([0, calculatedWidth])
  svg
    .append('g')
    .attr('transform', `translate(0, ${calculatedHeight})`)
    .attr('class', 'tick-labels-x')
    .call(
      axisBottom(x)
        .tickSize(0)
        .tickValues(xTickValues)
        .tickFormat(timeFormat('%-m/%-d') as (value: Date | {valueOf(): number}, i: number) => string)
    )

  // y-axis
  const y = scaleLinear().domain([0, max]).range([calculatedHeight, 0])
  svg.append('g').attr('class', 'tick-labels-y').call(axisLeft(y).ticks(4).tickSize(0))

  // grid for y
  svg
    .append('g')
    .attr('class', 'tick-y')
    .call(
      axisLeft(y)
        .ticks(4)
        .tickSize(-calculatedWidth)
        .tickFormat(() => {
          return ''
        })
    )

  // grid for x

  svg
    .append('g')
    .attr('class', 'tick-x')
    .attr('transform', `translate(0,${calculatedHeight})`)
    .call(
      axisBottom(x)
        .tickValues(xTickValues.slice(0, -1)) // we remove the last element from the array to avoid adding the last grid line
        .tickSize(-calculatedHeight)
        .tickFormat(() => {
          return ''
        })
    )

  // x-axis label
  svg
    .append('text')
    .attr('class', 'axis-label')
    .attr('text-anchor', 'middle')
    .attr('x', calculatedWidth / 2)
    .attr('y', height - margin.top)
    .text('Timeline')

  // y-axis label
  svg
    .append('text')
    .attr('class', 'axis-label')
    .attr('transform', 'rotate(-90)')
    .attr('x', -calculatedHeight / 2)
    .attr('y', -margin.bottom - 10)
    .attr('dy', '0.71em')
    .style('text-anchor', 'middle')
    .text('Quantity')

  // graph all the lines
  for (const l of lines) {
    svg
      .append('path')
      .datum(l.data)
      .attr('class', l.class)
      .attr(
        'd',
        line<GraphData>()
          .x(function (d) {
            return x(d.date)!
          })
          .y(function (d) {
            return y(d.value)!
          })
      )
  }

  // add all of the points
  for (const l of lines) {
    svg
      .append('g')
      .selectAll('dot')
      .data(l.data)
      .enter()
      .append('circle')
      .attr('cx', function (d) {
        return x(d.date)!
      })
      .attr('cy', function (d) {
        return y(d.value)!
      })
      .attr('r', 5)
      .attr('class', l.class)
      .on('mouseover', tip.show)
      .on('mouseout', tip.hide)
  }
}
